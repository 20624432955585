
import ApplicationLogo from "@/Components/ApplicationLogo";
import { Button } from "@/Components/ui/button";
import { Input } from "@/Components/ui/input";
import { setLoader } from "@/Hooks/Functions";
import { Icon } from "@iconify/react";
import { Link, useForm } from "@inertiajs/react";
import { Separator } from "@radix-ui/react-context-menu";
import { toast } from "sonner";

export default function Foooter({ auth }) {

    const { data, setData, post, reset } = useForm({
        email: auth?.user?.email || '',
    });

    const submit = () => {
        post(route('newsletter.subscribe'), {
            onStart: () => setLoader(true, 'Subscribing...'),
            onSuccess: () => toast.success('Subscribed successfully'),
            onError: (error) => toast.error(error.email),
            onFinish: () => { reset(); setLoader(false); }
        });
    }
    return (
        <div className="w-screen relative min-h-[40vh] flex flex-col divide-y justify-center items-center bg-card-foreground">
            <div className="md:container mx-auto flex md:flex-nowrap flex-wrap flex-row   justify-between items-stretch p-4 gap-4">
                <div className="md:w-2/6 w-full flex text-white flex-col justify-center items-center gap-2 p-4">
                    <ApplicationLogo white square className="h-[80px] w-fit" />
                    <div className="flex flex-row justify-center items-center gap-3 py-4 mb-4">
                        <Icon
                            icon="fa6-brands:facebook-f"
                            className="text-2xl"
                        />
                        <Icon
                            icon="fa6-brands:x-twitter"
                            className="text-2xl"
                        />
                        <Icon
                            icon="fa6-brands:instagram"
                            className="text-2xl"
                        />
                        <Icon icon="fa6-brands:youtube" className="text-2xl" />
                    </div>
                </div>
                <div className="md:!w-1/6 w-1/3 flex flex-col gap-4 text-white">
                    <h2 className="font-semibold">Customer Care</h2>
                    <Link href={route('about-us')} className="hover:underline">About Us</Link>
                    <Link href={route('contact-us')} className="hover:underline">Contact Us</Link>
                    <Link href={route('help-center')} className="hover:underline">Help Center</Link>
                </div>
                <div className="md:!w-1/6 w-1/3 flex flex-col gap-4 text-white">
                    <h2 className="font-semibold">More</h2>
                    <Link href={route('blogs')} className="hover:underline">Blogs</Link>
                    <Link href={route('faqs')} className="hover:underline">FAQs</Link>
                    <Link href={route('affiliate-program')} className="hover:underline">Affilliates Program</Link>
                </div>
                <div className="md:!w-2/6 w-full flex flex-col gap-4 text-white md:border-t-0 border-t md:pt-0 pt-4">
                    <h2>Newsletter</h2>
                    <p>
                        Sign up our newsletter to receive updates from our
                        store, promotions, and events.
                    </p>
                    <div className="flex flex-row justify-between gap-1 items-center">
                        <Input value={data.email} onChange={(e) => setData('email', e.target.value)} placeholder="Enter your email address" />
                        <Button onClick={submit} variant="theme">Submit</Button>
                    </div>
                </div>
            </div>
            <div className="md:container md:absolute bottom-4 mx-auto md:divide-x text-white flex md:flex-nowrap flex-wrap flex-row justify-center items-stretch p-4 gap-4">
                <span className="px-4">
                    Copyright © {new Date().getFullYear()}{" "}
                </span>
                <Link href={route("home")} className="px-4 hover:underline">
                    rockmyprompt.com
                </Link>
                <Link href={route('legalities', { page: 'privacy-policy' })} className="px-4 hover:underline">Privacy & Policy</Link>
                <Link href={route('legalities', { page: 'terms-of-service' })} className="px-4 hover:underline">Term of Services</Link>
            </div>
        </div>
    );
}
