import ApplicationLogo from "@/Components/ApplicationLogo";
import NavLink from "@/Components/NavLink";
import { cn } from "@/lib/utils";
import { Link, usePage } from "@inertiajs/react";
import { useScroll } from "ahooks";
import { useEffect, useState } from "react";
import UserPopover from "./UserPopover";
import { Button } from "@/Components/ui/button";
import { Icon } from "@iconify/react";
import { navigate } from "@/Hooks/Functions";



export default function Heaader({ className }) {
    const scroll = useScroll(document);
    const [scrolled, setScrolled] = useState(false);
    const [open, setOpen] = useState(false);
    const { auth } = usePage().props;

    useEffect(() => {
        setScrolled(scroll && scroll.top >= 100);
    }, [scroll]);

    const links = [
        {
            label: "Home",
            href: route("home"),
        },
        {
            label: "Hot Items",
            href: "#",
        },
        {
            label: "Templates",
            href: "#",
        },
        {
            label: "Collections",
            href: "#",
        },
        {
            label: "Shop",
            href: "#",
        },
    ];

    return (
        <div className={cn(`w-screen relative bg-primary`, !scrolled ? "!bg-transparent" : "!bg-primary", className)} >

            <div className="max-w-7xl p-2 w-full mx-auto flex flex-row justify-between items-center gap-2">
                <div className="flex w-fit flex-row justify-start items-center gap-2">
                    <Button onClick={() => setOpen(true)} variant="icon" className="group md:hidden inline-block">
                        <Icon className="text-white group-hover:text-teal-600 text-3xl" icon="charm:menu-hamburger" />
                    </Button>
                    <Link className="md:inline-flex hidden" href={route('home')}>
                        <ApplicationLogo
                            white={true}
                            square={false}
                            className="w-fit md:h-[60px] h-[50px] fill-current text-gray-500"
                        />
                    </Link>
                    <Link className="md:hidden inline-flex" href={route('home')}>
                        <ApplicationLogo
                            white={true}
                            square={true}
                            className="w-fit md:h-[60px] !h-[40px] fill-current text-gray-500"
                        />
                    </Link>
                </div>
                <div className="md:flex hidden flex-row justify-end items-center gap-x-4">
                    {links.map((link, index) => (
                        <NavLink
                            key={index}
                            href={link.href}
                            className={cn("text-white hover:text-teal-600")}
                        >
                            {link.label}
                        </NavLink>
                    ))}
                </div>
                <div className="flex flex-row justify-end items-center gap-x-4">
                    {!auth?.user && (
                        <Link href={route("login")}>
                            <Button>Sign In</Button>
                        </Link>
                    )}
                    {!auth?.user && (
                        <Link href={route("register")}>
                            <Button>Get Started</Button>
                        </Link>
                    )}
                    {!!auth?.user && <UserPopover iconColor="white" />}
                </div>
            </div>

            <div className={cn("absolute min-h-screen bg-primary top-0 left-0 w-screen z-[200]", open ? "" : "!hidden")}>
                <div className="w-full flex flex-col relative justify-center items-center gap-4 p-4 h-screen">
                    <Button variant="icon" onClick={() => setOpen(false)} className="absolute top-4 right-4">
                        <Icon className="text-white text-3xl" icon="charm:cross" />
                    </Button>
                    {links.map((link, index) => (
                        <Button variant="ghost"
                            key={index}
                            onClick={() => {
                                setOpen(false);
                                navigate(link.href)
                            }}
                            className={cn(
                                "text-white",
                                scrolled
                                    ? "hover:text-teal-600"
                                    : "hover:text-primary",
                            )}
                        >
                            {link.label}
                        </Button>
                    ))}
                </div>
            </div>
        </div >
    );
}
