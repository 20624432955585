import { Toaster } from "@/Components/ui/sonner";
import { cn } from "@/lib/utils";
import GlobalLoader from "@/Modules/GlobalLoader";
import { usePage } from "@inertiajs/react";
import { state } from "@state/store";
import AOS from "aos";
import { useEffect } from "react";
import { toast } from "sonner";
import { useSnapshot } from "valtio";
import Foooter from "./partials/Foooter";
import Heaader from "./partials/Heaader";

export default function HomeLayout({ headerClass, wrapperClass, children, hasFooter = true }) {
    const { flash } = usePage().props;

    useEffect(() => {
        if (!!flash?.message)
            toast.success(flash.message)
        if (!!flash?.error) {
            toast.error(flash.error)
        }
    }, [flash])

    useEffect(() => {
        AOS.init({
            duration: 1200,
        });
        AOS.refresh();
    }, []);

    const snap = useSnapshot(state)

    return (
        <div className={cn("min-h-screen h-full overflow-y-auto w-screen flex flex-col bg-gray-100", wrapperClass)}>
            <div className={cn("w-screen fixed !z-[100] left-0 top-0 bg-theme", headerClass)}>
                <Heaader />
            </div>
            <Toaster
                position="top-right"
                expand={false}
                closeButton
                richColors
            />


            {children}

            {hasFooter && <Foooter />}
            {snap.processing && <GlobalLoader />}
        </div>
    );
}
